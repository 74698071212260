var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"550","content-class":_vm.$vuetify.breakpoint.xs ? '' : 'rounded-xl',"fullscreen":_vm.$vuetify.breakpoint.xs,"persistent":""},on:{"input":_vm.resetForm},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"transition":"fade-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","depressed":""}},'v-btn',_vm.btnProps,false),Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v(" mdi-calendar-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("apppointments.add")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.loadingData || _vm.loading,"loading":_vm.loadingData || _vm.loading}},[_c('v-card-title',{staticClass:"headline primary white--text align-center justify-space-between"},[(_vm.editMode)?_c('span',[_vm._v(_vm._s(_vm.$t("Edit appointment")))]):_c('span',[_vm._v(_vm._s(_vm.$t("apppointments.add")))]),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_c('v-card-text',{staticClass:"pt-4"},[_c('v-form',{ref:"form",attrs:{"id":"create-appointment","name":"create-appointment"},on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center mr-2"},[_c('v-select',{attrs:{"label":"Type","rules":[_vm.editMode ? true : _vm.rules.required],"items":_vm.appointmenttypes,"item-text":"name","item-value":"name","outlined":"","dense":""},on:{"change":function($event){return _vm.getListNomenclators($event)}},model:{value:(_vm.form.nomTypeId),callback:function ($$v) {_vm.$set(_vm.form, "nomTypeId", $$v)},expression:"form.nomTypeId"}})],1)],1),_vm._v(" "+_vm._s(/* Time */)+" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('ma-date-picker',{attrs:{"label":"Date","datepickerProps":{
                'no-title': true,
                max: _vm.maxDate,
              },"future":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',{staticClass:"mx-2 d-flex flex-column"},[_c('ma-time-picker',{attrs:{"label":"Shedule"},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.appointmentDurations,"item-text":"label","item-value":"value","hide-details":"","outlined":"","dense":""},model:{value:(_vm.appointmentDuration),callback:function ($$v) {_vm.appointmentDuration=$$v},expression:"appointmentDuration"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(/* Patients */)+" "),_c('v-autocomplete',{attrs:{"label":_vm.getpatientN + 's',"loading":_vm.patientsLoading,"items":_vm.patients,"rules":[_vm.editMode ? true : _vm.rules.required],"item-text":"fullname","item-value":"uuid","autocomplete":"off","outlined":"","dense":""},on:{"change":_vm.onPatientChange},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.homePhone)}})],1)]]}}]),model:{value:(_vm.form.patientUuid),callback:function ($$v) {_vm.$set(_vm.form, "patientUuid", $$v)},expression:"form.patientUuid"}}),_vm._v(" "+_vm._s(/* Procedures */)+" "),(_vm.form.patientUuid)?_c('div',{staticClass:"d-flex align-start"},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.prodecuresShow,"loading":_vm.loadingProcedures,"filter":function (item, queryText) {
                    return (
                      item.type
                        .toLowerCase()
                        .includes(_vm.form.nomTypeId.toLowerCase()) ||
                      item.type.toLowerCase() == _vm.form.nomTypeId.toLowerCase()
                    );
                  },"label":"Procedure","item-text":"name","item-value":"procedureId","outlined":"","dense":""},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [(item)?_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.type)}}),_c('v-list-item-subtitle',[_c('span',{staticClass:"mr-3"},[_vm._v(" ID: "+_vm._s(item.procedureId))]),_c('span',{staticClass:"mx-1"},[_vm._v(" Type: "+_vm._s(item.name))]),_vm._v(" Created at: "+_vm._s(item.date)+" ")])],1):_vm._e()]}}],null,false,680474670),model:{value:(_vm.form.procedureId),callback:function ($$v) {_vm.$set(_vm.form, "procedureId", $$v)},expression:"form.procedureId"}},[_c('div',{staticClass:"\n                    text-body-2\n                    px-3\n                    font-weight-bold\n                    text--secondary\n                    unselectable\n                  ",attrs:{"slot":"no-data"},slot:"no-data"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" No procedures found for "),_c('em',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(_vm.form.nomTypeId.toLowerCase())}})],1)])],1):_vm._e()],1),_vm._v(" "+_vm._s(/* Employee */)+" "),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[(!_vm.isDoctor)?_c('v-autocomplete',{attrs:{"label":"Doctor","name":"doctors","items":_vm.doctors,"rules":[_vm.editMode ? true : _vm.rules.required],"loading":_vm.loadingEmployees,"filter":function (item, queryText) {
                  return (
                    item.rol
                      .toLowerCase()
                      .includes(queryText.toLowerCase()) ||
                    item.fullname
                      .toLowerCase()
                      .includes(queryText.toLowerCase())
                  );
                },"item-text":"fullname","item-value":"uuid","autocomplete":"off","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),(data.item.ocupation)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.ocupation.name)}}):_vm._e()],1)]}}],null,false,2738384696),model:{value:(_vm.form.employeeUuid),callback:function ($$v) {_vm.$set(_vm.form, "employeeUuid", $$v)},expression:"form.employeeUuid"}}):_vm._e()],1)],1),_c('v-textarea',{attrs:{"label":"Note","rows":"2","outlined":"","dense":"","hide-details":""},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"grey","text":"","rounded":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"form":"create-appointment","type":"submit","color":"primary","loading":_vm.loadingData,"disabled":_vm.formValid == false || _vm.loadingData,"width":"100","depressed":"","rounded":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.editMode ? "labelUpdate" : "labelCreate"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }